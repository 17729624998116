/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import {
    PopupComponent as SourcePopupComponent,
} from 'SourceComponent/Popup/Popup.component';
import { ESCAPE_KEY } from 'SourceComponent/Popup/Popup.config';

import './Popup.override.style';

/** @namespace Pwa/Component/Popup/Component */
export class PopupComponent extends SourcePopupComponent {
    handleKeyDown(e: KeyboardEvent) {
        switch (e.keyCode) {
        case ESCAPE_KEY:
            // this.hidePopupAndGoBack();
            break;
        default:
            break;
        }
    }
}

export default PopupComponent;
